
import { defineComponent, reactive, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import InputText from "@/components/primevueCustom/InputText.vue";
import { Form, Field } from "vee-validate";
import Button from "primevue/button";
import { AxiosResponse } from "node_modules/axios";
import { IAlert, IResponseData, ResultDatas } from "@/models/common";
import $API from "@/services";
import { messageType } from "@/models/message";

export default defineComponent({
  name: "Password",
  setup() {
    const state = reactive({
      password: "",
      newPassword: "",
      newPasswordConfirm: "",
    });
    const { t } = useI18n({ useScope: "global" });

    const changePassword = async () => {
      const res: AxiosResponse<IResponseData<string>> =
        await $API.SignService.changePassword(
          state.password,
          state.newPassword
        );
      const { data } = res;
      if (
        data.ResultData !== ResultDatas.SUCCESS &&
        !Array.isArray(data.ResultData)
      ) {
        alert.value = {
          title: "비밀번호 변경",
          content: data.ResultData,
          type: messageType.ERROR,
        };
      } else {
        alert.value = {
          title: "비밀번호 변경",
          content: "비밀번호가 변경 되었습니다.",
          type: messageType.INFO,
        };
      }
    };

    const alert: Ref<IAlert> = ref({
      title: "비밀번호 변경",
      type: messageType.ERROR,
      content: "",
    });

    return {
      t,
      state,
      changePassword,
      alert,
    };
  },
  components: {
    InputText,
    Form,
    Field,
    Button,
  },
});
